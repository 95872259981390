.all_kurse {
  padding-top: 2rem;
  padding-bottom: 5rem;
  min-height: 400px;
  h3 {
    font-size: 2rem;
    text-align: center;
  }
  hr {
    width: 60px;
    color: $primary-color;
    height: 4px;
    background-color: $primary-color;
    margin: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 2rem;
  }
}

.kurs_unter {
  padding: 2rem 0rem;
  .row {
    background-color: $secondary-color;
    max-width: 1100px;
    margin: auto;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    -moz-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow: 3px 3px 5px 6px #ccc;
  }
  .image_container {
    padding: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .main_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
  }
  .termin_container {
    padding-left: 4px;
  }
  .termin {
    font-size: 2rem;
    font-weight: 600;
  }
  .hour {
    font-size: 1.2rem;
  }

  .second-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem;
  }
  .left-part {
    font-size: 1.1rem;
  }

  .platz_container {
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .platz {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 12px;
    background-color: $green-color;
  }
  .wenig {
    background-color: $orange-color;
  }
  .booked {
    background-color: $gray-color;
  }

  .check_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .preis {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 1rem;
  }
  .btn {
    background-color: $third-color;
    &:hover {
      background-color: lighten($third-color, 10%);
    }
  }
  .kurs_leitung {
    font-weight: 600;
  }
  @media (max-width: 991.98px) {
    .main_content {
      flex-direction: column;
      justify-content: center;
    }
    .hour {
      text-align: center;
    }
  }
  @media (max-width: 767.98px) {
    .image_container {
      display: none;
    }
  }

  @media (max-width: 575.98px) {
    .termin,
    .hour {
      text-align: center;
    }

    .second-content {
      flex-direction: column;
    }
    .left-part {
      padding-bottom: 2rem;
    }

    .platz_container {
      justify-content: center;
    }
  }
  @media (max-width: 400.98px) {
    .check_container {
      flex-direction: column;
    }
    .preis {
      padding-bottom: 0.6rem;
    }
  }
}
