.hintergrund-bild {
  position: relative;
  background: url('../img-background/interesse.jpg') no-repeat center
    center/cover;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    background-color: #fff;
    padding: 2rem 5%;
    max-width: 320px;
    margin: auto;
    border-radius: 4px;
    border: 1px solid $primary-color;
  }
  .title {
    text-transform: uppercase;
    color: $gold-color;
    font-size: 2.2rem;
    text-align: center;
    padding-bottom: 0.5rem;
  }
  p {
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 1.5rem;
  }
  .link-container {
    text-align: center;
  }
}
