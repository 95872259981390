// Utilies
// fixed scrolling Traversy Media
.img-attachment {
  position: relative;
  background: url('../img-background/bild_7.jpg') no-repeat center center/cover;
  min-height: 400px;
  opacity: 0.8;
  background-attachment: fixed;
  text-transform: uppercase;
  a {
    padding: 0.6rem;
    background-color: $primary-color;
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 500px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
    cursor: pointer;
    transition: all 0.25s ease-out;
    border-radius: 4px;
    &:hover {
      background-color: lighten($primary-color, 5%);
      color: #fff;
    }
  }
  @media (max-width: 767.98px) {
    a {
      max-width: 400px;
      font-size: 1.2rem;
      padding: 0.4rem;
    }
  }
  @media (max-width: 575.98px) {
    a {
      max-width: 250px;
      padding: 0.2rem;
      letter-spacing: 0rem;
    }
  }
}
