.warteliste {
  padding: 3rem 0rem;
  h2 {
    font-size: 2rem;
    font-weight: 600;
    text-align: center !important;
  }
  hr {
    width: 60px;
    color: $primary-color;
    height: 4px;
    background-color: $primary-color;
    margin: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 2rem;
  }
  p {
    max-width: 750px;
    margin: auto;
    font-size: 1.2rem;
  }
}
