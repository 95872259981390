.test-area {
  min-height: 100vh;
}

.our_team {
  background-color: $secondary-color;
  padding: 2rem 0rem 5rem 0rem;
  .content {
    max-width: 900px;
  }
  .title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center !important;
  }
  hr {
    width: 60px;
    color: $primary-color;
    height: 4px;
    background-color: $primary-color;
    margin: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 2rem;
  }
  .desc {
    padding-bottom: 2rem;
    font-size: 1.2rem;
  }
  .btn_container {
    text-align: center;
  }
  @media (max-width: 575.98px) {
    .desc {
      font-size: 1rem;
    }
  }
}
