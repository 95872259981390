.kontakt {
  background-color: $light-color;
  padding: 2rem 0rem;
  padding-bottom: 6rem;
  .content-container {
    margin: auto;
    margin-bottom: 1rem;
    max-width: 600px;
  }
  .title {
    font-size: 2.2rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
    color: $primary-color;
  }
  .desc {
    margin-bottom: 0rem;
    font-size: 1.2rem;
    padding-bottom: 2rem;
  }

  .input-container {
    padding-bottom: 0.8rem;
    p {
      padding-bottom: 0.2rem;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
  .law-container {
    display: flex;
    padding: 1rem 0rem;
    font-size: 0.9rem;
    a {
      font-size: 0.9rem;
    }
  }

  textarea {
    height: 200px;
  }
  .btn-container {
    text-align: center;
    button {
      text-transform: uppercase;
    }
  }
  .birthday-input {
    max-width: 120px;
  }
  small {
    display: none;
    color: $red-color;
    font-weight: 600;
    font-size: 1rem;
    transition: all 0.25 ease-out;
    &.error {
      display: block;
    }
  }
}
