.boxes {
  margin-top: 2rem;
  h3 {
    text-align: center;
    font-size: 2rem;
  }
  hr {
    width: 60px;
    color: $primary-color;
    height: 4px;
    background-color: $primary-color;
    margin: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 3rem;
  }
  .box {
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    -moz-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow: 3px 3px 5px 6px #ccc;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    img {
      height: 240px;
      width: 100%;
      object-fit: cover;
      border-bottom: 0.5rem solid $primary-color;
    }
    .title {
      padding: 0 2%;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 600;
      padding-bottom: 0.2rem;
    }
    p {
      padding: 0 2%;
      font-size: 1rem;
    }
    .full-btn {
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      transition: all 0.25s ease-out;
      min-height: 60px;
      a {
        background-color: $primary-color;
        text-align: center;
        width: 100%;
        font-size: 1.1rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        transition: all 0.25s ease-out;
      }
      &:hover {
        background-color: darken($primary-color, 10%);
        a {
          background-color: darken($primary-color, 10%);
        }
      }
    }
  }
}
