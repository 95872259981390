.suche {
  padding-top: 2rem;
  .such_box {
    background-color: #fff;
    padding: 1rem 10px;
    border: solid 2px $primary-color;
    border-radius: 4px;
  }
  hr {
    width: 120px;
    color: $primary-color;
    height: 4px;
    background-color: $primary-color;
    margin: auto;
    border: none;
    margin-top: 2rem;
  }
  .title {
    font-size: 2rem;
    font-weight: 600;
    color: $primary-color;
    text-align: center !important;
  }
  .sub-title {
    text-align: center !important;
    font-size: 1.4rem;
  }
  .calendars_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 600px;
    margin: auto;
  }
  .one_calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
    font-size: 1.2rem;
    p {
      padding-right: 0.8rem;
    }
    .fa-calendar {
      padding-right: 0.4rem;
      color: $primary-color;
    }
    .fa-chevron-down {
      padding-left: 0.4rem;
    }
  }
  .calendar {
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }
  .btn_container {
    padding-top: 1rem;
    text-align: center;
  }
  button {
    background-color: $third-color;
    &:hover {
      background-color: lighten($third-color, 10%);
    }
  }
  @media (max-width: 575.98px) {
    .calendars_container {
      flex-direction: column;
    }
    .title {
      font-size: 1.7rem;
    }
  }
}
