@import './../../scss/config';
.loader {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: -10;
  width: 100%;
  opacity: 0;
  transition: all 0.6s ease-in;
  &.active {
    z-index: 10;
    opacity: 1;
  }
}

/* Loader circles */
.loader > span {
  background-color: $gold-color;
  border-radius: 50%;
  margin: 5rem 0.6rem;
  animation: bouncingLoader 0.6s infinite alternate;
  -webkit-animation: bouncingLoader 0.6s infinite alternate;
}

.loader > span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > span:nth-child(3) {
  animation-delay: 0.4s;
}

/* Define the animation called bouncingLoader. */
@keyframes bouncingLoader {
  from {
    width: 0.1rem;
    height: 0.1rem;
    opacity: 1;
    transform: translate3d(0);
  }
  to {
    width: 1rem;
    height: 1rem;
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}

@-webkit-keyframes bouncingLoader {
  from {
    width: 0.1rem;
    height: 0.1rem;
    opacity: 1;
    transform: translate3d(0);
  }
  to {
    width: 1rem;
    height: 1rem;
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.6s ease-in;
  min-height: 100vh;
  height: 100%;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -10;
  opacity: 0;
  &.active {
    z-index: 10;
    opacity: 0.8;
    pointer-events: none;
  }
}

.disable-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: -10;
  width: 100%;
  opacity: 0;
  &.active {
    z-index: 10;
    opacity: 1;
    cursor: not-allowed;
  }
}
