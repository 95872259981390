.testimonials {
  padding: 3rem 0rem 5rem 0rem;
  text-align: center !important;
  h3 {
    text-align: center;
    font-size: 2rem;
  }
  hr {
    width: 60px;
    color: $primary-color;
    height: 4px;
    background-color: $primary-color;
    margin: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 3rem;
  }
  .card {
    border-bottom: 3px $primary-color solid !important;
    transition: $transition;
    padding: 0rem 4px;
    margin-top: 60px;
    background-color: $secondary-color;
    &:hover {
      transform: scale(1.05);
    }
  }
  .card-img-top {
    background-color: $primary-color;
    color: #ffffff;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    line-height: 75px;
    margin: -40px auto 0 auto;
  }
  .card-body {
    padding-top: 1rem;
  }
  .card-footer {
    padding: 0.5rem 0rem 1rem 0rem;
    color: lighten(#333, 30%);
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      padding-right: 0.4rem;
      color: lighten(#333, 30%);
    }
  }
  @media (max-width: 991.98px) {
    .testimonial_2 {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .testimonial_1 {
      display: none;
    }
  }
}
