$header-height: 300px;
.showcase {
  position: relative;
  background: url('../img-background/bild_0.jpg') no-repeat center center/cover;
  min-height: $header-height;
  top: -8px;
  border-bottom: $primary-color solid 2px;
  box-shadow: 0 5px 5px -5px lighten(#333, 50%);
  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.4);
  }
  .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: $header-height;
  }
  h1 {
    font-size: 3.2rem;
    margin-bottom: 2rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    span {
      text-transform: none;
      font-size: 1.8rem;
      display: block;
    }
  }
  .btn_container {
    margin-top: 2rem;
  }
  a {
    font-size: 1.2rem;
  }
  i {
    color: #fff;
    background-color: $primary-color;
    margin-right: 0.5rem;
  }

  @media (max-width: 575.98px) {
    h1 {
      font-size: 2.8rem;
      span {
        font-size: 1.2rem;
      }
    }
  }
  @media (max-width: 450.98px) {
    h1 {
      font-size: 2rem;
      span {
        font-size: 1.2rem;
      }
    }
    a {
      font-size: 1rem;
    }
  }
}
