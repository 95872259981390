.test-navbar {
  min-height: 100vh;
}
.z-nav {
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 0rem 0rem;
  width: 100%;
  background-color: $primary-color;
  color: #fff;
  // margin-bottom: 4px;
  position: relative;
  box-shadow: 0 5px 5px -5px lighten(#333, 50%);
  .z-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;
    color: $primary-color;
    font-weight: 600;
    font-size: 2rem;
    cursor: pointer;
  }
  .z-nav-pages {
    display: flex;
    padding: 0rem 2rem;
    align-items: center;
    .z-nav-page {
      padding-right: 2rem;
      a {
        color: $dark-color;
        font-size: 1.2rem;
        font-weight: 600;
        transition: $transition;
        &:hover {
          color: lighten(#333, 10%);
        }
      }
    }
  }
  .z-burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;
    cursor: pointer;
    div {
      width: 25px;
      height: 3px;
      background-color: $dark-color;
      margin: 2.5px;
      transition: $transition;
    }
  }
  .z-toggle {
    div {
      background-color: #fff !important;
    }
    .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    .line2 {
      opacity: 0;
    }
    .line3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }
  @media (max-width: 767.98px) {
    .z-nav-pages {
      background: #fff;
      box-shadow: 0 5px 5px -5px lighten(#333, 50%);
      display: flex;
      position: absolute;
      right: 0px;
      top: 60px;
      min-width: 300px;
      max-width: 800px;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      transform: translateX(100%);
      z-index: 10;
      opacity: 0.9;
    }
    .z-nav-page {
      opacity: 0;
      padding: 1.2rem 0rem;
      padding-right: 0rem !important;
    }
    .z-burger {
      display: flex;
      z-index: 100;
    }
  }
}
@media (max-width: 767.98px) {
  .mobile-overflow-wrapper {
    overflow-x: hidden;
  }
}

@keyframes navSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes navSlideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
